
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;0,700;1,600;1,700&display=swap');

body{
  background: white; 
  /* background: #fafafa;  */
   font-family: 'Poppins', sans-serif  !important;
} 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.MuiSelect-select:focus { 
  background-color:transparent !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.App { 
  display: 'flex';
  /* width: '320px'; */
  padding: '10px'; 
  position: 'relative';
  overflow-x: hidden; 
  background: 'cornsilk';
  font-family: proxima-nova !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.GoogleLogin{
  padding: 10px;
  /* width: 300px; */
}
.GoogleLogin span{
  font-weight: 800 !important;
}
.GoogleLogin button{
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
  padding-left: 10px !important;
  background: #ebebeb !important; 
}
.GoogleLogin div{
  background: #ebebeb !important;
}
.FBLogin{
  padding: 10px; 
}
.FBLogin button:hover{
  background: #4667ad;
}
.FBLogin button{
  background: #3b5895;
  border: none !important;
  width: 100%;
  border-radius: 2px !important;
  padding: 12px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 750;
  letter-spacing: 0.4px !important;
  font-size: 14px; 
  text-align: left;
}
.Mui-expanded{
  margin: 0 !important;
}
.TandC{
  opacity: 0.6;
  padding: 8px;
  font-weight: 600; 
}
.TandC a{
  text-decoration: none; 
}

/* media 1600*/
  .overlayLanding{
    /* margin-top:  -80px;
    font-size: 15px!important; */
    font-size: 40px!important;
    position: relative;
    top: 70px !important;
  }
  .textSize {
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 25px;
  }
  .btnExplore {
    font-size: 26px !important;
    font-weight: normal;
    margin-top: 0;
}
 .chromeBtn{
   background-color: black;
   float: left;
   text-transform: none;
   color: red;
   border:none;
   border-radius: 10px;
   height:55px;
   width: 325px;
    font-size: 21px;
    margin-top: 40px;
}
/* .textSize{
  font-size: 70px;
} */
.landingTextSize{
  font-size: 57px;
}
.landingTextSize2{
  font-size: 26px;
}
.landingDescription{
  max-width: 67%;
  margin: 0 auto;
  font-size: 28px;
  margin-top: 30px;
}
.pTagCanadianRealtors{
  font-size: 28px;
  /* font-size: 20px; */
  opacity: 1.0;
  color: black;
  font-weight: 500;
  width: 80%;
  margin: 0 auto;
}
.pTagCanadianRealtors p{
  margin-bottom: 20px;
}
button.chromeBtn {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.GoogleAppleBtn{
  margin-Top:40px;
   float: left 
} 
.GooglePlayBtn {
  margin-Left: 26px!important 
}
.rootPaperRealtorCard{
  /* width: 216px;
    height: 232px;
    margin: 8px; */
    width: 266px!important;
    height: 232px!important;
    margin: 8px auto!important;
}
.rootPaperBankCard{
  width: 154px;
  height: 195px;
  margin: 8px;
}
#tableCompare tr td {
  width: 20px !important
  }
  h1.landingTextSize {
    font-size: 30px;
    font-size: 45px;
    letter-spacing: 0px !important;
}

.relator_bank div img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 160px;
}
.WyzeGuideBtn {
  float: right!important;
  width: auto!important;
  text-Transform: none!important;
  background-color: red!important;
  color: white!important
}
.redirectBtn1 {
  float: right!important;
  width: auto!important;
  padding: 0px 22px!important;
  text-Transform: none!important;
  background-color: red!important;
  color: white!important
}
.redirectBtn2 {
  float: right!important;
  width: auto!important;
  padding: 0px 22px!important;
  text-Transform: none!important;
  background-color: red!important;
  color: white!important
}
.loginRegisterLink{
  margin-top: 6px!important; 
 }
 .CorousalContainer{
  /* margin-left: 10%!important; */
  height: 450px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  align-items: center
}
 
 /* media 1600*/
 
 .box {
  background-color: white;
  padding: 10px;
  width: 50%!important;
  /* left: 22px; */
  margin: 32px;
  margin-bottom: 18px;
  height: 100%;
  margin-top: 1px
}
.compareBtnTop {
  color: white;
  float: right;
  right: 63px;
  padding: 7px;
  margin-top: 25px;
   /* backgroundColor: red; */
  top: 67px;
  position: fixed;
}
.btn {
  margin-Top: 12px;
  color: green;
   /* display: inline-block; */
  top:100px;
  right:178px;
  position:absolute;
  margin-Left: 10px;
   /* marginRight: 10px; */
height:39px;
padding-Top:0px !important;
padding-Left:0px !important;
padding-Right:0px !important;
   border: solid;
   /* margin: theme.spacing(2); */
}

/* @media(min-width: 1280px){
  .overlayLanding{
    margin-top:  -100px;
    font-size: 17px!important;
  }
 .btnExplore{
   font-size: 18px!important;
 }
} */

.mainImage{
  background-image: url(./assest/banner.png);
  /* background-position: top 0px!important; */
  background-position: center right -90px!important;
    background-repeat: no-repeat;
    padding: 0px;
    padding-bottom: 100px;
    background-size: cover;
   background-color: #A8AEB2;
}
.tabSize{
  width:88%!important;
}
.tabStyle {
  min-width: 125px;
  background-color: white;
  color: black;
}
.inviteScroll{
  height:130px;
  overflow-y: auto;
}

/* custom stepper css */
.scrollDiv{
  overflow-y: scroll;
  max-height: 290px;
  direction: rtl;
}
.scrollDiv::-webkit-scrollbar {
  width: 4px;
}
.scrollDiv::-webkit-scrollbar-track {
  background: #c5c5c5;
  border-radius: 100px;
  /* border-left: 100px solid #c5c5c5;
  border-right: 100px solid #c5c5c5; */
  width: 10px;
}
.scrollDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:#ff0000;
    width: 5px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);       */
}
.WyzeGuideBtn{
  margin-top: 0px;
}
.corousalBG{
  display: flex!important;
  position: relative!important;
  /* padding-top: 50px!important; */
}
.corousalInner{
  display: flex;
  position: absolute;
  top: 0px;
  /* top: 50%;
  transform: translateY(-50%); */
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px 0px;
}
.corousalInn{
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.paddingArea{
  padding-left: 100px!important;
  padding-right: 0px!important;
  padding-top: 0px!important
}
.corousalOuter{
    margin-top: 4%;
    max-width: 1250px;
    margin: 0 auto;
    text-align : center;
}
/* .listStyling{
  list-style-type: "-  "!important;
} */
@media(max-width: 1400px){
  .corousalOuter{
    width: 100%;
    margin: 0
  }
}

@media(max-width: 1100px){
  .tabSize{
    width:100%!important;
    /* min-width: 158px; */
  }
  .overlayLanding{
    padding-left: 15px;
  }
 .btnExplore{
   font-size: 20px!important;
 }
 .GoogleAppleBtn{
   margin-Top: 35px;
    float: left 
 } 
 .chromeBtn{
  background-color: black;
  float: left;
  text-transform: none;
  color: red;
  border-radius: 10px;

  width: 190px;
  font-size: 14px;
  /* margin-top: 60px; */
}
/* .textSize{
  font-size: 70px;
} */
.landingTextSize{
  font-size: 57px;
}
.pTagCanadianRealtors{
    font-size: 26px;
    /* font-size: 20px; */
    opacity: 1.0;
}
 .box {
  background-color: white;
  padding: 10px;
  width: 50%!important;
  /* left: 22px; */
  margin: 32px;
  margin-bottom: 18px;
  height: 100%;
  margin-top: 1px
}
.buttonProgress {
  color: "green";
  position: 'absolute';
  left: 0;
  right: 39px;
  margin: auto;
  margin-top: 24px;
  text-transform: none;
}
.compareBtnTop {
  color: white;
  float: right;
  right: 42px;
  padding: 7px;
  margin-top: 25px;
   /* backgroundColor: red; */
  top: 78px;
  position: fixed;
}
.btn {
  margin-Top: 12px;
  color: green;
   /* display: inline-block; */
  top:110px;
  right:162px;
  position:fixed;
  margin-Left: 10px;
  height: 39px;
   /* marginRight: 10px; */
  border: solid;
  padding-Top:0px !important;
  padding-Left:0px !important;
  padding-Right:0px !important;
   /* margin: theme.spacing(2); */
}
.textSize {
  font-size: 50px;
  line-height: 80px;
  margin-bottom: 25px;
}
button.chromeBtn {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.rootPaperRealtorCard{
  /* width: 188px!important;
  height: 204px!important;
  margin: 8px!important; */
  width: 240px!important;
    height: 232px!important;
    margin: 8px auto!important;
}
.rootPaperBankCard{
  width: 154px!important;
  height: 195px!important;
  margin: 8px!important;
}
.GooglePlayBtn {
  margin-Left: 26px 
}
#tableCompare tr td {
  width: 20px !important
  }
  h1.landingTextSize {
    font-size: 45px;
    letter-spacing: 0px !important;
}

.relator_bank div img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 160px;
}
.imgGoogleApplebtn{
  width: 250px !important;
  float: left;
  margin: 10px auto;
}
.WyzeGuideBtn {
  float: right!important;
  width: auto!important;
  text-Transform: none!important;
  background-color: red!important;
  color: white!important
}
.loginRegisterLink{
  margin-top: 4px 
 }
 .testimonial {
  min-height: 400px;
}
.landingLogo{
  padding-left: 15px!important;
}
  }
  @media(max-width: 650px){
    .redirectBtn1 {
      position: absolute!important;
      width: 72px!important;
      font-size: 14px!important;
      right:50px;
      top: 50px;
    }
    .redirectBtn2 {
      position: absolute!important;
      width: 72px!important;
      font-size: 14px!important;
      right:50px;
      top: 15px;
    }
    .landingDescription{
      max-width: 90%;
    }
    .CorousalContainer{
      height: auto;
    }
    .testimonial {
      min-height: 800px;
    }
    .corousalBG{
      padding-top: 0px!important;
      padding-left: 15px!important;
    }
    .HeadLogo{
      margin-left: 0px!important;
    }
    .WyzeGuideBtn{
      right: 10px!important;
    }
    .landingLogo{
      margin-left: 10px!important;
    }
    .slider_work {
      padding-left: 40px;
    }
    .corousalBG{
      position:unset;
    }
    .corousalInner{
      position: unset;
      left: 0px;
      transform: none;
    }
    .paddingArea{
      padding-left: 0px!important
    }
    .overlayLanding{
      padding-left: 0px;
    }
    .landingLogo {
      padding-left: 0px!important;
    }
  }


@media(max-width: 450px){
  .MuiCardActionArea-root {
    width: 90% !important;
    display: block;
    margin: 25px auto !important;
    left: 0px !important;
  }
  .tabSize{
    width:100%!important;
    /* min-width: 158px; */
  }
  .textSize {
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 25px;
    margin-top:0 !important;
    
  }
  .imgGoogleApplebtn{
    width:90px
  }
  .overlayLanding{
    margin-top:  -19px!important;
    font-size: 8px!important;
    position: relative;
    top:10px !important;
    /* size:1px!important; */
    /* font-size: 0em; */
  }
 .btnExplore{
   font-size: 13px !important;
   
    /* top: -1px!important; */
    margin-top: -20px;
 }
 .chromeBtn{
  background-color: black;
  float: left;
  text-transform: none;
  color: red;
  border-radius: 7px;
  height: 35px !important;
  width: 175px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top:20px !important;

  /* margin-top: 60px; */
}
/* .textSize{
  font-size: 70px;
} */
.landingTextSize{
  font-size: 57px;
}
.landingTextSize2{
  font-size: 18px;
}
.pTagCanadianRealtors{
  font-size: 22px;
  /* font-size: 20px; */
    opacity: 1.0;
    color: black;
    font-weight: 500;
}
 .box {
  background-color: white;
  padding: 10px;
  width: 100%!important;
  /* left: 22px; */
  margin: 32px;
  margin-bottom: 18px;
  height: 100%;
  margin-top: 1px
}
.buttonProgress {
  color: "green";
  position: absolute;
  left: 173px!important;
  right: 0px;
  margin: auto;
  margin-top: 24px;
  text-transform: none;
}
.compareBtnTop {
  color: white;
  float: right;
  right: 54px;
  padding: 7px;
  margin-top: 25px;
   /* backgroundColor: red; */
  top: 82px;
  position: fixed;
}
.btn {
  margin-Top: 12px;
  color: green;
   /* display: inline-block; */
  top:113px;
  height: 39px;
  right:174px;
  position:fixed;
  margin-Left: 10px;
  padding-Top:0px !important;
padding-Left:0px !important;
padding-Right:0px !important;
   /* marginRight: 10px; */
  border: solid;
   /* margin: theme.spacing(2); */
}
button.chromeBtn {
  font-size: 8px;
  font-weight: 400;
  cursor: pointer;
}
.GoogleAppleBtn{
  margin-Top: 4px;
   float: left ;
  
} 
.GooglePlayBtn {
   margin-Left: 3px 
}
.rootPaperRealtorCard{
  width: 188px!important;
  height: 204px!important;
  margin: 20px!important;
  left: 80px!important;
}
.rootPaperBankCard{
  width: 90% !important;
  height: 231px!important;
  margin: 20px auto !important;
}
.WyzeGuideBtn {
  float: right!important;
  width: auto!important;
  text-Transform: none!important;
  background-color: red!important;
  color: white!important;
  /* top:14px!important */
}
.loginRegisterLink{
   margin-top: 0px;
   /* margin-right: 40px!important; */
   margin-bottom: 15px!important;
   text-align: end;
  }
#tableCompare tr td {
  width: 20px !important
  }
  h1.landingTextSize {
    font-size: 24px;
    /* font-size: 38px; */
    letter-spacing: 0px !important;
}

.relator_bank div img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 160px;
}
.mainImage{
  background-image: url(./assest/banner.png);
  background-position: top 0px!important;
    background-repeat: no-repeat;
  
    background-size: cover;
    padding:20px;
}
.redirectBtn {
  width: 50px!important;
}
  }
  
  /* #tableCompare tr td {
  width: 20px !important
  }
  h1.landingTextSize {
    
    font-size: 60px;
    letter-spacing: 0px !important;
}

.relator_bank div img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 160px;
} */
/* .textSize {
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 25px;
} */
/* button.chromeBtn {
  font-size: 22px;
  font-weight: 600;
} */
.canadian_bank button {
    height: 200px;
}
.canadian_bank button div {
  box-shadow: none !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.canadian_bank .MuiCardContent-root {
  position: absolute;
  bottom: -20px !important;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  top: auto !important;
}.canadian_bank .MuiCardContent-root {
    position: absolute;
    bottom: -10px !important;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    top: auto !important;
}
.canadian_bank .MuiCardContent-root  p{
 font-size: 18px;
}
.sliderClass .slide.animateOut {
	-webkit-transition-delay: .1s;
					transition-delay: .1s;
}
.slider_work {
  /* opacity: .3; */
  text-align: left;
  /* padding-left: 40px; */
}
.slider_work p {
    margin-top: 10px ;
    font-size: 15.5px;
    color: black!important;
}
.faq{
  text-align: left;
  box-shadow: none;
}
.faq > div{
  box-shadow: none;
  border:1px solid #ddd;
}
.faq  div{
  box-shadow: none;
  color: #444;
}
.faq > .Mui-expanded {
  border: 1px solid red;
}
.testimonial .awssld__content {
  background: none;
}
.testimonial p {
  text-align: justify;
  margin: 7px auto;
  font-size: 15px;
  clear: both;
}
/* .testimonial .awssld__controls {
  display: none;
} */
.testimonial .awssld__container {
  display: block;
  width: 100%;
  height: 310px;
  padding-top: 0;
  padding-bottom: 0;
}
.testimonial {
  height: 320px;
  overflow: hidden;
}
.awssld{
  top:-10px;
}
.sliderBg{
  background: url('./assest/bg.png');
}
.GoogleAppleBtn img {
  width: 300px;
}
.chromeBtn{
  width: 230px;
}
.swalWide{
  width: 300px !important;
  height: 320px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: block;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: block;
  scrollbar-width: block; /* Firefox */
}
::-webkit-scrollbar {
  width: 15px;
}


/* To return selected text
*[unselectable="on"] {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  -ms-user-select: none;
  user-select: none;
} */
